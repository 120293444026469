cs.ns('app.ui.trait.root.hash')
app.ui.trait.root.hash.model = cs.trait({
    protos: {

        create () {
            this.base()
            cs(this).model({
                "global:data:qrcode":        { value: "x", valid: "string" },
                "event:hashChange":          { value: "", valid: "string", autoreset: true },
                "state:automaticHashChange": { value: false, valid: "boolean" }
            })
        }

    }
})